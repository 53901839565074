import {
    Box,
    Button,
    Card,
    CardContent,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import { MixTheme, useStyle } from '@styles';
import { Images } from '@constants';
import Image from 'next/image';
import React, { useCallback } from 'react';
import { makeStyles } from './login.styles';
import bg from '@public/images/background.svg';
import { SaveButton } from '@components/Atoms';
import { LogoutOptions } from '@auth0/auth0-react';

interface LoginLayoutProps {
    onWalletConnect: () => void;
    onMetaMaskConnect: () => void;
    onLoginClick: () => void;
    showSignUpVerify: boolean;
    logout: (options?: LogoutOptions | undefined) => void;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({
    onWalletConnect,
    onLoginClick,
    onMetaMaskConnect,
    showSignUpVerify,
    logout
}: LoginLayoutProps) => {
    const showWalletLogin = false;
    const theme: MixTheme = useTheme();
    const { styles } = useStyle(makeStyles);
    const handleLogout = useCallback(() => {
        localStorage.clear();
        logout({ returnTo: window.location.origin });
        return;
    }, [logout]);

    return (
        <Stack
            sx={{
                backgroundImage: `url(${bg.src})`,
                backgroundSize: 'cover'
            }}
        >
            {showSignUpVerify ? (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '10px'
                        }}
                    >
                        <SaveButton
                            title={'Logout'}
                            reverse={true}
                            width="fit-content"
                            backgroundColor={theme?.color?.black}
                            hoverBackgroundColor={theme?.color?.black}
                            onClick={handleLogout}
                        />
                    </Box>
                    <Stack
                        direction={'row'}
                        padding={'0px 0px 40px 0px'}
                        justifyContent={'center'}
                        sx={styles.header}
                        height={'calc(100vh - 100px)'}
                        alignItems={'center'}
                    >
                        <Stack
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Card sx={styles.registerCard}>
                                <CardContent>
                                    <span
                                        style={{
                                            marginLeft: '100px'
                                        }}
                                    >
                                        Thank you for registering with us!
                                    </span>
                                    <br />
                                    <span>
                                        We have sent a verification mail for
                                        your registered email <br />
                                        id please click on the &quot;Verify
                                        account&quot; button in the mail
                                        <br /> to confirm your account and login
                                        to your account
                                    </span>

                                    <Button
                                        onClick={onLoginClick}
                                        variant="contained"
                                        sx={styles.loginOrSignup}
                                    >
                                        Login
                                    </Button>
                                </CardContent>
                            </Card>
                        </Stack>
                    </Stack>
                </>
            ) : (
                <Stack
                    direction={'row'}
                    padding={'30px 0px'}
                    justifyContent={'center'}
                    sx={styles.header}
                    alignItems={'center'}
                    height={'100vh'}
                >
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        maxWidth={'680px'}
                        margin={'auto 25px'}
                    >
                        <Box
                            border={`4px solid ${theme?.color?.white}`}
                            padding={'20px 32px'}
                            borderRadius={'4px'}
                        >
                            <Image
                                src={'/images/mojito-logo.svg'}
                                width={'124px'}
                                height={'32px'}
                            />
                        </Box>
                        <Card sx={{ ...styles.loginCard, width: '100%' }}>
                            <CardContent sx={{ padding: '16px' }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={styles.cardTitle}
                                >
                                    Welcome back
                                </Typography>
                                <Typography
                                    variant="h4"
                                    style={styles.cardSubTitle}
                                >
                                    Log in to Mojito
                                </Typography>
                                {showWalletLogin && (
                                    <>
                                        <Button
                                            variant="contained"
                                            sx={styles.maskConnectButton}
                                            onClick={onMetaMaskConnect}
                                            startIcon={
                                                <Image
                                                    src={
                                                        '/images/meta_mask_logo.png'
                                                    }
                                                    width={'16px'}
                                                    height={'16px'}
                                                />
                                            }
                                        >
                                            Metamask
                                        </Button>
                                        <Button
                                            variant="contained"
                                            sx={styles.loginWithMojito}
                                            startIcon={
                                                <Image
                                                    src={
                                                        Images.walletConnectLogo
                                                    }
                                                    width={'16px'}
                                                    height={'16px'}
                                                />
                                            }
                                            onClick={onWalletConnect}
                                        >
                                            WalletConnect
                                        </Button>
                                    </>
                                )}
                                <Stack sx={{ padding: '1rem 0 0' }}>
                                    {showWalletLogin && (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                sx={styles.cardTitle}
                                            >
                                                Don’t have a wallet?
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                sx={styles.cardSubTitle}
                                            >
                                                Log in with email
                                            </Typography>
                                        </>
                                    )}
                                    <Button
                                        variant="contained"
                                        sx={styles.loginWithEmail}
                                        onClick={onLoginClick}
                                    >
                                        Login
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default LoginLayout;
