import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
    getOrganizationId,
    getSignature,
    loginOrSignup
} from '@graphql/queries/loginQueries';
import { useWeb3ModalConnect } from '@state/Web3ModalConnect';
import { RouterPath } from '@constants';
import { StorageService } from '@services/StorageService';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import LoginLayout from '@layouts/authentication/login/login.layout';
import { Environment } from '@constants';
import { useToast } from '@state/ToastProvider';
import { Toasttypes } from '@components/Atoms';
import { useMeStore } from '@state/MeProvider';

const LoginPage = () => {
    const {
        connect,
        networkId,
        onWalletConnect,
        getSignedAddress,
        onMetaMaskConnect
    } = useWeb3ModalConnect();
    const { meData } = useMeStore();
    const { data: orgData } = useQuery(getOrganizationId, {
        variables: { org: Environment.ORGANIZATION_NAME },
        skip:
            (meData?.me?.userOrgs?.filter(
                (item) =>
                    item.organization?.handle ==
                    process.env.NEXT_PUBLIC_ORGANIZATION_NAME
            ) ?? 0) == 0
    });

    const orgId = orgData?.organization?.id;

    const { data: signatureData } = useQuery(getSignature, {
        skip: !connect?.account || !orgId || !networkId,
        variables: {
            orgId,
            walletAddress: connect?.account,
            networkID: networkId
        }
    });

    const [loginWithSignature] = useMutation(loginOrSignup);
    const { loginWithRedirect, isAuthenticated, error, logout } = useAuth0();
    const router = useRouter();
    const { setToast } = useToast();

    const [showSignUpVerify, setShowSignUpVerify] = useState(false);

    useEffect(() => {
        if (error && !isAuthenticated) {
            if (error) {
                setToast(error?.message, Toasttypes.ERROR);
            }
            setShowSignUpVerify(true);
        }
    }, [isAuthenticated, error, setToast]);

    const handleLogin = useCallback(async () => {
        try {
            await loginWithRedirect({
                appState: {
                    returnTo: RouterPath.drops
                }
            });
        } catch (e) {
            console.error('[Login]-login', e);
        }
    }, [loginWithRedirect]);

    const approveSignature = useCallback(async () => {
        try {
            const message = signatureData?.getSignatureMessage;
            const signedAddress = await getSignedAddress(message);

            if (signedAddress) {
                const { signature, address } = signedAddress;
                const response = await loginWithSignature({
                    variables: {
                        challenge: message,
                        signature,
                        signer: address,
                        chainId: connect.chainId,
                        orgId
                    }
                });
                StorageService.token.setValue(
                    response?.data?.loginWithSignature?.token
                );
                router.push(RouterPath.drops);
            }
        } catch (e) {
            console.error('[Login]-approveSignature', e);
            localStorage.clear();
        }
    }, [
        orgId,
        router,
        connect?.chainId,
        signatureData,
        getSignedAddress,
        loginWithSignature
    ]);

    useEffect(() => {
        if (signatureData?.getSignatureMessage) {
            approveSignature();
        }
    }, [signatureData, approveSignature]);

    return (
        <LoginLayout
            onMetaMaskConnect={onMetaMaskConnect}
            onWalletConnect={onWalletConnect}
            onLoginClick={handleLogin}
            showSignUpVerify={showSignUpVerify}
            logout={logout}
        />
    );
};

export default LoginPage;
